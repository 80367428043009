<template>
  <div
    class="sourceSearch left"
    ref="sourceSearch">
    <el-select
      v-model="selectedSourceInfo"
      popper-class="recevierShowList"
      filterable
      v-no-emoji
      remote
      reserve-keyword
      :remote-method="remoteMethod"
      @focus="requestSourceList"
      :loading-text="$t('i18n_loading')"
      :placeholder="socketItemData.lastLiveName || $t('lang.EnterSourceNamePeerID')"
      class="recevierShowSource"
      :loading="loadingInput"
      @change="selectSearchList"
      :disabled="receiverItemOps.status == 0 ? true : false"
      @visible-change="searchSelect"
      ref="changeTab"
      autocomplete="off">
      <el-option
        v-for="item in filterSourceArr"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :title="item.name + '(' + item.peerId + ')'"
        v-show="showSelectInput"
        :disabled="item.status == 0">
        <span
          style="float: left"
          class="ellipsisInput"
          :class="{
            red: item.status == '2',
            green: item.status == '1',
            brow: item.status == '0',
            white: item.status == '3',
          }">{{ item.label }}</span>
        <i
          class="iconfont i-eyeView-show check"
          :class="checkIsCheck(item)"></i>
        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}
        </span>
      </el-option>
    </el-select>
    <el-cascader-panel
      v-show="!showSelectInput"
      class="recevierCas"
      :options="searchId"
      v-model="showSource"
      :key="receiverItemOps.peerId"
      @change="selectdSource()"
      :props="{ expandTrigger: 'hover' }">
      <template slot-scope="{ node, data }">
        <span
          class="iconfont"
          v-html="changIcon(data)"
          style="margin-right: 5px"></span>
        <span :title="node.isLeaf ? data.name + '(' + data.peerId + ')' : ''">
          <span
            :class="{
              red: data.status == '2',
              green: data.status == '1',
              brow: data.status == '0',
              white: data.status == '3',
              ellipsis: node.isLeaf,
            }"
            :style="{
              'margin-left': node.isLeaf ? '5px' : '0px',
            }">{{ data.label }}</span>
        </span>
        <i
          class="iconfont i-eyeView-show check"
          v-if="node.isLeaf"
          :class="checkIsCheck(data)"></i>
        <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
      </template>
    </el-cascader-panel>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    receiverItemOps: Object,
    socketItemData: Object,
    socketRInfo: Object, // 从R获取的实时信息
    useState: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      loadingInput: false,
      showSelectInput: true, // 判断显示哪个输入框,
      searchId: [],
      showSource: [], // v-model的次联框的值
      selectedSourceInfo: '',
      filterSourceArr: [],
      isSelectNewR: false, // 是否重新选择了source
      deviceList: '',
      socketItemDataObj: this.socketItemData,
      sourceArr: []
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      showSizeClass: state => state.common.showRecevierSize,
      rStatus: state => state.common.selectRStatus,
      receiverRList: state => state.receiverRList
    })
  },
  mounted () {
    document.addEventListener('visibilitychange', this.getPageHide)
    this.$once('hook:mounted', () => {
      document.removeEventListener('click', this.clickEvent, true)
      document.addEventListener('click', this.clickEvent, true)
    })
    if (this.receiverItemOps.status == 2) {
      this.selectedSourceInfo = this.receiverItemOps.livePeerName || this.socketItemDataObj.livePeerName
    } else if (this.receiverItemOps.status == 1 && this.socketRInfo?.WorkMode == 1) {
      this.selectedSourceInfo = this.socketRInfo.CurrentTName
    }
  },
  watch: {
    receiverItemOps (val, oldval) {
      if (this.useState) {
        this.socketItemDataObj = this.receiverRList[val.peerId]
        this.selectedSourceInfo = this.receiverItemOps.livePeerName || this.socketItemDataObj.livePeerName
        if (this.receiverItemOps.status == 2) {
        // 第一次将live的source Id 和Rid拼接
          const rid = this.receiverItemOps.peerId ? this.receiverItemOps.peerId : ''
          this.deviceList = this.receiverItemOps.livePeerId + ',' + rid
        } else {
          this.selectedSourceInfo = val.type == 'changeR' ? '' : this.selectedSourceInfo
        }
      }
    },
    socketItemData (val, oldval) {
      if (this.useState) {
        this.selectedSourceInfo = this.$store.state.selectSourceName
      } else {
        if (val.status == 2 && !this.isSelectNewR) {
          this.selectedSourceInfo = val.livePeerName
        } else {
          // Live On Hold
          if (val.status == 1 && this.socketRInfo?.WorkMode == 1 && !this.isSelectNewR && this.socketRInfo.CurrentTStrId) {
            this.selectedSourceInfo = this.socketRInfo.CurrentTName
            this.deviceList = this.socketRInfo.CurrentTStrId.toLowerCase() + ',' + val.peerId
          }
        }
      }
      this.socketItemDataObj = val
    }
  },
  created () {
    if (this.receiverItemOps.status == 2) {
      // 第一次将live的source Id 和Rid拼接
      const rid = this.receiverItemOps.peerId ? this.receiverItemOps.peerId : ''
      this.deviceList = this.receiverItemOps.livePeerId + ',' + rid
    }
  },
  methods: {
    getPageHide (e) {
      if (e.target.visibilityState == 'visible') {
        this.$refs.changeTab.blur()
        this.showSelectInput = true
      }
    },
    clickEvent (e) {
      const tar = e.srcElement || e.target
      try {
        if (!this.$refs.sourceSearch.contains(tar)) {
          this.showSelectInput = true
        }
      } catch (error) { }
    },
    selectdSource () {
      let arr = [] // 可以选择的source所有信息数组
      this.searchId.map(v => {
        v.children.map(item => {
          arr.push(item)
        })
      })
      arr.map(i => {
        if (i.peerId == this.showSource[1]) {
          if (i.status == '0') {
            this.$message({
              type: 'warning',
              message: this.$t('lang.home_clickOfflineR')
            })
            this.isSelectNewR = false
          } else if (i.status == '3') {
            this.$message({
              type: 'warning',
              message: this.$t('lang.NoSource')
            })
            this.isSelectNewR = false
          } else {
            this.isSelectNewR = true
            this.deviceList = i.peerId + ',' + this.socketItemDataObj.peerId
          }
          this.selectedSourceInfo = i.name
          this.$emit('changeList', {
            isSelectNewR: this.isSelectNewR,
            deviceList: this.deviceList,
            sId: i.peerId,
            sourceArray: this.sourceArr,
            selectType: i.type
          })
        }
      })
      this.$refs.changeTab.blur()
      this.showSelectInput = true // 选择完让其隐藏
      // myReceiversAnalyze("myRecevierselectSource", this.$route.path);
    },
    remoteMethod (query) {
      if (query != '') {
        // myReceiversAnalyze("myRecevierSearchSource", this.$route.path);
        this.showSelectInput = true // 次级选择框隐藏
        this.searchApi(query)
      } else {
        this.filterSourceArr = []
        this.showSelectInput = false // 次级选择框显示
      }
    },
    searchApi (query) {
      const url = '/ccp/tvucc-device/userDevice/querySourcelistFRByPack'
      const param = {
        peerId: this.receiverItemOps.peerId,
        condition: query
      }
      this.axios.post(url, param, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        this.loadingInput = false
        this.filterSourceArr = []
        this.sourceArr = []
        const data = res.data.result
        let onlineArr = []
        let liveArr = []
        let offLineArr = []
        let onlineNosource = []
        data.map((item, i) => {
          let obj = {}
          obj.label = Object.keys(data[i])[0]
          obj.value = Object.keys(data[i])[0]
          if (obj.label == 'TVU Anywhere') obj.label = 'TVUAnywhere'
          if (obj.label == 'TVU Pack') obj.label = 'TVUPack'
          if (obj.label == 'Grid') obj.label = 'TVU Grid'
          Object.values(data[i])[0].map(v => {
            v.label = obj.label + '/' + v.name
            v.value = v.peerId
            this.sourceArr.push(v)
            if (v.status == '1') onlineArr.push(v)
            if (v.status == '3') onlineNosource.push(v)
            if (v.status == '2') liveArr.push(v)
            if (v.status == '0') offLineArr.push(v)
          })
        })
        this.filterSourceArr = this.filterSourceArr
          .concat(onlineArr)
          .concat(onlineNosource)
          .concat(liveArr)
          .concat(offLineArr)
      }).catch(() => {})
    },
    requestSourceList () {
      this.showSelectInput = false
      $('.el-cascader-menu__empty-text').html(
        this.$t('lang.i18n_loading')
      )
      let arr = []
      // let that = this
      const url = '/ccp/tvucc-device/userDevice/querySourcelistFRByPack'
      const param = {
        peerId: this.receiverItemOps.peerId,
        condition: ''
      }

      this.axios.post(url, param, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(res => {
        this.sourceArr = []
        const data = res.data.result
        data.map((item, i) => {
          let obj = {}
          obj.label = Object.keys(data[i])[0]
          obj.value = Object.keys(data[i])[0]
          if (obj.label == 'TVU Anywhere') obj.label = 'TVUAnywhere'
          if (obj.label == 'TVU Pack') obj.label = 'TVUPack'
          if (obj.label == 'Grid') obj.label = 'TVU Grid'
          Object.values(data[i])[0].map(v => {
            v.label = v.name
            v.value = v.peerId
            if (v.status == '0') {
              v.disabled = true
            }
            this.sourceArr.push(v)
          })
          obj.children = Object.values(data[i])[0]
          arr.push(obj)
        })
        this.searchId = []
        if (arr.length == 0) {
          $('.el-cascader-menu__empty-text').html(
            this.$t('lang.NoResult')
          )
        }
        arr.map(item => {
          this.searchId.push(item)
        })
      }).catch(() => {})
    },
    selectSearchList () {
      this.filterSourceArr.map(i => {
        if (i.peerId == this.selectedSourceInfo) {
          if (i.status == '0') {
            this.$message({
              type: 'warning',
              message: this.$t('lang.home_clickOfflineR')
            })
            this.isSelectNewR = false
          } else {
            if (this.receiverItemOps.status == 2) {
              if (this.receiverItemOps.livePeerId == i.peerId) {
                // 如果选择的是当前souce/R正在live设备 将this.$emit('changeHadSelectDevice', false) 置为false
                this.isSelectNewR = false
              } else {
                this.isSelectNewR = true
                this.deviceList = i.peerId + ',' + this.socketItemDataObj.peerId
              }
            } else {
              this.isSelectNewR = true
              this.deviceList = i.peerId + ',' + this.socketItemDataObj.peerId
            }
          }
          this.selectedSourceInfo = i.name
          this.$emit('changeList', {
            isSelectNewR: this.isSelectNewR,
            deviceList: this.deviceList,
            sId: i.peerId,
            sourceArray: this.sourceArr,
            selectType: i.type
          })
        }
      })
    },
    searchSelect (i) {
      if (i) this.showSelectInput = false
    },
    changIcon (i) {
      let str = ''
      if (i.label == 'External') {
        str = '&#xe725;'
      } else if (i.label == 'TVUAnywhere') {
        str = '&#xe766;'
      } else if (i.label == 'TVUPack') {
        str = '&#xe641;'
      } else if (i.label == 'TVU Grid') {
        str = '&#xe764;'
      } else if (i.label == 'Local SDI') {
        str = '&#xe75f;'
      }
      return str
    },
    checkIsCheck (item) {
      // 判断当前是否是正在和当前设备live的设备
      if (this.socketItemDataObj.status == 2) {
        const livePeerId = this.socketItemDataObj.livePeerId
        if (livePeerId && livePeerId.toLowerCase() == item.peerId.toLowerCase()) return 'show'
      } else {
        return ''
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // 销毁组件的时候 关闭监听 防止重复监听 浪费资源
    document.removeEventListener('click', this.clickEvent, true)
    next()
  },
  destroyed () {
    document.removeEventListener('visibilitychange', this.getPageHide)
  }
}
</script>
<style lang="less" scoped>
.red {
  color: red;
}

.show {
  opacity: 1 !important;
}

.ellipsis {
  width: 130px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.check {
  display: inline-block;
  float: right;
  color: #39aa50;
  opacity: 0;
  font-weight: bold;
  margin-left: -6px;
  &.i-eyeView-show:before {
    font-size: 16px;
  }
}

.white {
  color: white;
}

.brow {
  color: #777;
}
.green {
  color: #33AB4f;
}
.locationSet {
  position: absolute;
  cursor: pointer;
  height: 25px;
  /*width: 178px;*/
  width: 100%;
  font-size: 0.1rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  line-height: 25px;
  z-index: 1;
  color: #fff;
  right: 0;
  top: 0;

  a {
    display: inline-block;
    width: 52%;
    height: 100%;
    margin-left: 48%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    /*background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, rgba(0, 0, 0, 0) 100%);*/
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    padding: 0 4px;
  }
}

.defaultBgImg {
  width: 100%;
  height: 100%;
  background: url('../assets/img/logo_thumbnail.png') no-repeat center;
  background-size: 100%;
}

.defaultBgImg img {
  width: 100%;
  height: 100%;
}

::v-deep .el-input .el-input__inner {
  border: none;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: #424242 !important;
}

::v-deep .el-input.is-disabled:hover {
  border: none;
}

::v-deep .el-button--info.is-plain.is-disabled,
::v-deep .el-button--info.is-plain.is-disabled:hover,
::v-deep .el-button--info.is-plain.is-disabled:focus,
::v-deep .el-button--info.is-plain.is-disabled:active {
  background: #676767;
}

::v-deep .el-image {
  width: 100%;
  height: 100%;
}
.sourceSearch {
  width: calc(100% - 100px);
  height: 32px;
  margin-left: 8px;
  z-index: 1;
  ::v-deep input {
    border: none;
  }

  ::v-deep .el-select {
    width: 250px;
  }

  ::v-deep .el-input__inner {
    color: #33ab4f;
  }

  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: #8d8d8d;
  }

  ::v-deep .el-cascader-panel.is-bordered {
    border: none;
    margin-top: 3px;
    position: relative;
    z-index: 2075;

    .el-cascader-menu {
      background-color: #242424;
      min-width: 180px;
    }

    .el-cascader-node__label {
      display: flex;
      color: white;
    }
    .el-cascader-node {
      font-weight: normal;
    }
  }

  ::v-deep .el-cascader-node {
    padding: 0 5px 0 5px;
  }

  .scrollbar {
    position: absolute;
    top: 34px;
    left: 0;
    width: 100%;
    height: 212px;
    overflow-y: auto;
    background: #444;
    margin-bottom: 30px;
  }

  .scrollbar li {
    display: block;
    width: 100%;
    color: #fff;
    height: 26px;
    line-height: 26px;
    cursor: pointer;
    position: relative;
    text-indent: 40px;
    text-align: left;
  }

  .scrollbar li:hover {
    background: #455f45;
  }

  .scrollbar::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 7px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: rgba(144, 147, 153, 0.2);
    opacity: 0.7;
  }

  .scrollbar::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    background: none;
  }

  ::v-deep .inputColorLive input {
    color: #33ab4f;
  }

  ::v-deep .inputColorOffline input {
    color: #676767 !important;
  }

  ::v-deep .inputColorOffline input::placeholder {
    color: #676767 !important;
  }

  ::v-deep .el-cascader {
    line-height: 32px;
    width: 100%;
  }

  ::v-deep .el-cascader-panel {
    height: 220px;

    .el-cascader-menu {
      height: 220px;
    }
  }

  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>
