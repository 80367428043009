<template>
  <!--在source列表选择R 或在R列表选择source的组件-->
  <div class="selectSource">
    <i
      class="arrow-icon"
      @click="changeIconStatus('handle')"
      :class="[visibleStatus ? 'el-icon-arrow-up': 'el-icon-arrow-top']"></i>
    <el-select
      v-model="valueModel"
      filterable
      v-no-emoji
      :placeholder="deviceType.toLowerCase()=='r' ? sourceItem.lastLiveName || $t('lang.EnterSourceNamePeerID') : sourceItem.lastLiveName || $t('lang.home_EnterReceiverNamePeerID')"
      size="medium"
      :no-match-text="$t('lang.NoResult')"
      remote
      :remote-method="getData"
      :popper-append-to-body=true
      popper-class="selectSourceNav"
      v-scroll="scrollData"
      :disabled="currentItem.status == 0 || timeLockPage && timeLocked"
      @change="sendInfo"
      value-key="peerId"
      @visible-change="changeIconStatus"
      :class="[valueModel!= ''||isPlace ? 'colorLive':'colorOnline']"
      ref="selectFocus">
      <el-option
        v-for="(item) in allData"
        :key="item.peerId"
        :value="item"
        :label="item.name"
        :disabled="item.status == 0"
        :class="{'gray':item.status == 0,'red':item.status == 2}"
        class="textLeft"
        :title="item.name + '(' + item.peerId + ')'">

        <div class="name">{{item.name}}</div>
        <i
          class="iconfont i-eyeView-show check"
          :class="checkIsCheck(item)"></i>
        <!--elmentUi 不能直接使用value传递对象 需要select上面加上value-key = id ，然后在option里面的key = id 就可以通过value传递对象了-->
      </el-option>
    </el-select>
  </div>
</template>
<script>
/*
 * 提供搜索R或source下拉的功能
 */
import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'

export default {
  props: {
    from: {
      type: String,
      default: ''
    },
    fromPage: {
      type: String,
      default: ''
    },
    timeLocked: {
      type: Boolean,
      default: false
    },
    timeLockPage: {
      type: Boolean,
      default: false
    },
    sourceItem: { // taskInfo里面的数据
      type: Object,
      default: function () {
        return {}
      }
    },
    hadSelectDevice: { // 获取VideoButton.vue按钮是否操作完成,和当前是否选择了其它不在和当前source/R live的source/R
      type: Boolean,
      default: false
    },
    type: { // 引用类型(在什么内使用,source内搜索R就传T,在R内搜索source就传R)
      type: String,
      default: 'T'
    },
    analyzeKey: { // 引用类型(在什么内使用,source内搜索R就传T,在R内搜索source就传R)
      type: String,
      default: ''
    }
  },
  data () {
    return {
      scrollData: {
        onScroll: this.onScroll,
        distance: 10,
        // directive: '.el-select-dropdown__list',
        directive: '.selectSourceNav .el-select-dropdown__wrap'
      },
      valueModel: '',
      placeholder: this.$t('lang.home_EnterReceiverNamePeerID'),
      condition: '', // 使用elementUI select数值变化时候的值
      data: '',
      page: 1,
      size: 15, // 每次滚动获取多少条的R或source
      hasNextPage: true, // 是否有下一页,以便滚动下拉
      allData: [], // 总得source或R列表
      currentItem: this.sourceItem,
      isSelectR: this.hadSelectDevice, // 是否重新选择了R
      isPlace: false, // 是否是显示的占位符this.$t('lang.home_EnterReceiverNamePeerID')
      defaulLivePeerId: this.sourceItem.status == 2 ? this.sourceItem.livePeerId : '', // 用以保存以前的livePeerId用以判断选择的R是否是以前live的
      oldLivePeerId: this.sourceItem.status == 2 ? this.sourceItem.livePeerId : '',
      deviceType: this.type, // 获取source下拉 还是R的下拉
      nHomeAnalyzeKey: this.analyzeKey, // 用于接收新home的谷歌分析
      visibleStatus: false,
      autoClickFlag: false
    }
  },
  created () {
    if (this.sourceItem.status == 2) {
      this.defaulLivePeerId = this.sourceItem.livePeerId
      this.oldLivePeerId = this.sourceItem.livePeerId
      let getSelectInfo = ''
      if (this.deviceType.toLowerCase() != 'r') {
        const deviceList = this.sourceItem.peerId + ',' + this.sourceItem.livePeerId
        getSelectInfo = {
          rid: this.sourceItem.livePeerId,
          peerId: this.sourceItem.peerId,
          deviceList: deviceList
        }
      } else {
        const deviceList = this.sourceItem.livePeerId + ',' + this.sourceItem.peerId
        getSelectInfo = {
          rid: this.sourceItem.peerId,
          peerId: this.sourceItem.livePeerId,
          deviceList: deviceList
        }
      }

      this.$emit('getSelectInfo', getSelectInfo)// 使用内建函数传递当前数据到父组件
    }

    if (this.sourceItem.livePeerName && this.sourceItem.status == 2) {
      this.valueModel = this.sourceItem.livePeerName
    }
  },
  computed: {},
  watch: {
    sourceItem (val) {
      this.currentItem = val
      if (!this.isSelectR) { // 如果没有重新选择R
        if (this.deviceType.toLowerCase() != 'r') {
          if (val.gridLivePeerId != null && this.defaulLivePeerId && val.gridLivePeerId.includes(this.defaulLivePeerId)) { // 当选中的R是和当前的source在live的
            let disIndex = ''// 记录要显示的livePeerName的下标
            const gridLivePeerIdArr = val.gridLivePeerId ? val.gridLivePeerId.split(';') : []
            gridLivePeerIdArr.map((item, index) => {
              if (item.toLowerCase() == this.defaulLivePeerId.toLowerCase()) {
                disIndex = index
              }
            })
            if (disIndex || disIndex == 0) {
              this.valueModel = val.gridLivePeerName ? val.gridLivePeerName.split(';')[disIndex] : ''
              const deviceList = val.gridLivePeerId ? `${val.peerId},${val.gridLivePeerId.split(';')[disIndex]}` : val.gridLivePeerId
              const getSelectInfo = {
                rid: val.peerId,
                peerId: val.gridLivePeerId ? val.gridLivePeerId.split(';')[disIndex] : '',
                deviceList: deviceList
              }
              this.$emit('getSelectInfo', getSelectInfo)// 使用内建函数传递当前数据到父组件
            }
          } else {
            if (val.gridLivePeerName != null && this.valueModel != '' && !val.gridLivePeerName.includes(this.valueModel)) { // 如果当前显示的Rname不在liveName列表内,重置当前显示的liveName
              if (val.livePeerName) {
                this.valueModel = val.livePeerName
                const deviceList = val.peerId + ',' + val.livePeerId
                const getSelectInfo = {
                  rid: val.peerId,
                  peerId: val.livePeerId,
                  deviceList: deviceList
                }
                this.$emit('getSelectInfo', getSelectInfo)// 使用内建函数传递当前数据到父组件
              }
            }
          }
          if (val.status == 2 && val.livePeerId != this.oldLivePeerId) {
            // this.valueModel = val.livePeerName;
            if (!this.valueModel) {
              this.valueModel = val.livePeerName
            }
            this.oldLivePeerId = val.livePeerId
            const deviceList = val.peerId + ',' + this.oldLivePeerId
            const getSelectInfo = {
              rid: val.peerId,
              peerId: this.oldLivePeerId,
              deviceList: deviceList
            }
            this.$emit('getSelectInfo', getSelectInfo)// 使用内建函数传递当前数据到父组件
          }
        } else {
          if (val.status == 2 && val.livePeerId != this.oldLivePeerId) {
            this.valueModel = val.livePeerName
            this.oldLivePeerId = val.livePeerId
            const deviceList = this.oldLivePeerId + ',' + val.peerId
            const getSelectInfo = {
              rid: val.peerId,
              peerId: this.oldLivePeerId,
              deviceList: deviceList
            }
            this.$emit('getSelectInfo', getSelectInfo)// 使用内建函数传递当前数据到父组件
          }
        }
      }
    },
    hadSelectDevice (val) { // 监听VideoButton.vue是否操作完成(stop,start)
      this.isSelectR = val
    },
    type (val) {
      this.deviceType = val
    },
    analyzeKey (val) {
      this.nHomeAnalyzeKey = val
    }
  },
  methods: {
    changeIconStatus (flag) {
      // console.log(flag)
      if (flag === 'handle') {
        if (!this.autoClickFlag && !this.visibleStatus) {
          this.$refs.selectFocus.focus()
          // this.getAllData();
        }
        this.autoClickFlag = false
      } else {
        this.autoClickFlag = true
        this.visibleStatus = flag
        if (flag) {
          this.getAllData()
        }
      }
    },
    checkIsCheck (item) { // 判断当前是否是正在和当前设备live的设备
      if (this.currentItem.status == 2) {
        if (this.deviceType.toLowerCase() != 'r') {
          const gridLivePeerId = this.currentItem.gridLivePeerId && this.currentItem.gridLivePeerId.split(';')
          if (gridLivePeerId && gridLivePeerId.includes(item.peerId)) {
            return 'show'
          }
        } else {
          const livePeerId = this.currentItem.livePeerId
          if (livePeerId && livePeerId.toLowerCase() == item.peerId.toLowerCase()) {
            return 'show'
          }
        }
      } else {
        return ''
      }
    },
    getAllData () { // select聚焦的时候,搜索为空的当前页的数据
      // console.log();
      this.allData = []
      this.condition = ''
      this.page = 1
      this.hasNextPage = true
      this.search()
    },
    getData (value) { // select输入框变化的时候搜索当前页的数据
      this.condition = value
      this.hasNextPage = true
      this.allData = []
      this.page = 1
      this.search()
    },
    search (isScoll) { // 搜索
      let url = ''
      let param = {}
      if (this.deviceType == 'R') {
        url = this.fromPage == 'multilControl' ? '/ccp/tvucc-user/userDevice/listPackByTimeLockFeature' : '/ccp/tvucc-device/userDevice/querySourcelistFR'
        param = {
          peerId: this.sourceItem.peerId,
          condition: this.condition,
          pageNum: this.page,
          pageSize: this.size
        }
        this.axios.post(url, param, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } }).then(res => {
          const data = res.data
          this.resoveData(data, isScoll, 'R')
        }).catch(() => {})
      } else {
        url = '/ccp/tvucc-user/userDevice/pageSourceAvailableRSort'// 默认的请求R的地址
        param = {
          sourceId: this.sourceItem.peerId,
          pageNum: this.page,
          pageSize: this.size,
          condition: this.condition,
          deviceType: this.sourceItem.type ? this.sourceItem.type.toUpperCase() : ''
        }
        this.axios.get(url, { params: param }).then(res => {
          const data = res.data
          if (res.data.result.list.length > 0) {
            this.resoveData(data, isScoll)
          } else {
            if (this.condition == '' && isScoll == 'undefined') {
              this.$message({
                type: 'warning',
                message: this.$t('lang.sourceNoHaveR')
              })
            }
          }
        }).catch(() => {})
      }
    },
    resoveData (data, isScoll, type) { // type为标识是处理什么的数据
      if (type == 'R') {
        if (data.errorCode == '0x0') {
          const result = data.result
          if (!result) return
          const resultList = this.fromPage == 'multilControl' ? data.result.list : data.result.resultList
          if (this.page < result.totalPageNum) {
            this.hasNextPage = true
          } else {
            this.hasNextPage = false
          }
          if (isScoll) {
            this.allData = this.allData.concat(resultList)
            // this.$el.querySelector('.el-scrollbar__wrap').scrollTop = this.page * 270 // 使滚动条 滚动到顶部
          } else {
            this.allData = []
            this.allData = resultList
          }
        }
      } else {
        if (data.errorCode == '0x0') {
          const list = data.result.list
          this.hasNextPage = data.result.hasNextPage
          if (isScoll) {
            this.allData = this.allData.concat(list)
            // this.$el.querySelector('.el-scrollbar__wrap').scrollTop = this.page * 270 // 使滚动条 滚动到顶部
          } else {
            this.allData = []
            this.allData = list
          }
        }
      }
    },
    onScroll () { // 滚动时触发
      if (this.hasNextPage) {
        this.page += 1
        this.search(true)
      }
      // console.log('触发');
    },
    queryInfo (list) {
      // 请求数据
      this.rlistArr = this.rlistArr.concat(list)
    },
    sendInfo (val) { // el-select获取值
      if (val.status == 0) { // R 离线
        this.$message({
          type: 'warning',
          message: this.$t('lang.home_clickOfflineR')
        })
        this.isPlace = false
        this.$emit('changeHadSelectDevice', false, this.sourceItem.peerId)
        return false
      } else {
        if (this.deviceType.toLowerCase() != 'r') { // 当前引用的不是receiverItem,是在source下引用的下拉
          if (this.currentItem.status == 2) {
            if (this.currentItem.gridLivePeerId != null && this.currentItem.gridLivePeerId.includes(val.peerId)) { // 当选中的R是和当前的source在live的
              let disIndex = ''// 记录要显示的livePeerName的下标
              const gridLivePeerIdArr = this.currentItem.gridLivePeerId && this.currentItem.gridLivePeerId.split(';')
              gridLivePeerIdArr.map((item, index) => {
                if (item.toLowerCase() == val.peerId.toLowerCase()) {
                  disIndex = index
                }
              })
              this.valueModel = this.currentItem.gridLivePeerName && this.currentItem.gridLivePeerName.split(';')[disIndex]
              this.isSelectR = false
              this.oldLivePeerId = this.currentItem.gridLivePeerId[disIndex]
              this.$emit('changeHadSelectDevice', false, this.sourceItem.peerId)
            } else {
              this.isSelectR = true
              this.$emit('changeHadSelectDevice', true, this.sourceItem.peerId) // 将已经选择了新R的信息传递给父组件
            }
          } else {
            this.isSelectR = true
            this.$emit('changeHadSelectDevice', true, this.sourceItem.peerId)
          }
          this.defaulLivePeerId = val.peerId// 将选择完的Rid保存
        } else {
          if (this.currentItem.status == 2) {
            if (this.currentItem.livePeerId == val.peerId) { // 如果选择的是当前souce/R正在live设备 将this.$emit('changeHadSelectDevice', false) 置为false
              this.isSelectR = false
              this.$emit('changeHadSelectDevice', false, this.sourceItem.peerId)
            } else {
              this.isSelectR = true
              this.$emit('changeHadSelectDevice', true, this.sourceItem.peerId) // 将已经选择了新R的信息传递给父组件
            }
          } else {
            this.isSelectR = true
            this.$emit('changeHadSelectDevice', true, this.sourceItem.peerId)
          }
        }

        this.isPlace = true
        // this.value = val.name;
      }
      var getSelectInfo = {}
      if (val.type.toLowerCase() == 'r') {
        const deviceList = this.sourceItem.peerId + ',' + val.peerId
        getSelectInfo = {
          rid: this.sourceItem.peerId,
          peerId: this.sourceItem.peerId,
          deviceList: deviceList
        }
      } else {
        const deviceList = val.peerId + ',' + this.sourceItem.peerId
        getSelectInfo = {
          rid: this.sourceItem.peerId,
          peerId: val.peerId,
          name: this.sourceItem.name,
          deviceList: deviceList
        }
      }
      this.$emit('getSelectInfo', getSelectInfo)// 使用内建函数传递当前数据到父组件
      if (this.nHomeAnalyzeKey) {
        newHomeAnalyze(this.nHomeAnalyzeKey, this.$route.path) // Home-Receiver-search
      }
    }
  }
}
</script>
<style lang="less" scoped>
.arrow-icon {
  position: absolute;
  bottom: 10px;
  right: 5px;
  z-index: 10;
}

::v-deep .el-select-dropdown .el-select-dropdown__item.selected:after {
  content: '';
}

.show {
  opacity: 1 !important;
}

.check {
  display: inline-block;
  float: right;
  color: #39aa50;
  font-size: 18px;
  opacity: 0;
  font-weight: bold;
}

.name {
  display: inline-block;
  float: left;
  padding-left: 8px !important;
  position: relative;
  z-index: 2;
  max-width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;

  &.noPadding {
    padding-left: 0;
  }
}

.selectSource {
  width: 100%;
  height: 100%;
  position: relative;

  ::v-deep .el-input {
    margin: 0 4px;
  }

  ::v-deep .el-select {
    width: 100%;
    height: 100%;
  }

  ::v-deep .el-input {
    width: 100%;
    height: 100%;
  }

  ::v-deep .el-input__inner {
    width: 100%;
    height: 100%;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  /*::v-deep .el-scrollbar__view{*/
  /*max-height: 244px;*/
  /*}*/
}

.el-select-dropdown__item {
  width: 100% !important;
}

// ::v-deep .el-select-dropdown__item span {
//   display: block;
//   width: 90%;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

::v-deep .selectSourceNav {
  /*max-height: 214px !important;*/
}

.selectSourceNav {
  /*max-height: 214px !important;*/
}

::v-deep .el-scrollbar {
  /*max-height: 214px !important;*/
}

/*::v-deep .el-icon-arrow-up:before {*/
/*content: "\e6e1";*/
/*}*/
// ::v-deep .el-select__caret:before {
//   content: "\E60B";
// }
.el-icon-arrow-top:before {
  content: '\e60b';
}

.el-icon-arrow-up:before {
  content: '\e60c';
}

::v-deep .el-scrollbar__wrap {
  /*max-height: 214px !important;*/
}

::v-deep .el-select-dropdown__list {
  height: 200px !important;
}

::v-deep .el-scrollbar__wrap {
  height: 214px !important;

  .el-scrollbar__view {
    height: 210px !important;
    overflow-y: auto;

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background-color: rgba(144, 147, 153, 0.2);
      opacity: 0.7;
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      border-radius: 10px;
      background: none;
    }
  }
}

/*::v-deep .el-scrollbar__bar {*/
/*  height: 100%;*/
/*}*/

/*::v-deep .el-select-dropdown__list::-webkit-scrollbar-track {*/
/*background-color: #444;*/
/*!*-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.22);*!*/
/*}*/

/*!*定义滚动条高宽及背景*!*/
/*::v-deep .el-select-dropdown__list::-webkit-scrollbar {*/
/*width: 6px;*/
/*background-color: rgba(0, 0, 0, 0.34);*/
/*}*/

/*!*定义滚动条*!*/
/*::v-deep .el-select-dropdown__list::-webkit-scrollbar-thumb {*/
/*background-color: #8b8b8b;*/
/*border-radius: 10px;*/
/*}*/

/*::v-deep .el-scrollbar__bar.is-vertical {*/
/*display: none;*/
/*}*/

// ::v-deep .el-select__caret {
//   transform: rotateZ(0) !important;
// }

::v-deep .el-select-dropdown__item {
  padding: 0 14px;
}

// ::v-deep .el-input__suffix {
//   display: block;
// }

.textLeft {
  text-align: left;
}

.red {
  color: #f00 !important;
}

.gray {
  color: #999 !important;
}

::v-deep .colorOnline .el-input__inner {
  color: white;
}

::v-deep .colorOnline .el-input__inner::placeholder {
  color: rgba(108, 108, 108, 1);
}

::v-deep .colorLive .el-input__inner {
  color: #33ab4f !important;
}
</style>
