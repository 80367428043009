<template>
  <el-dialog
    :visible.sync="showDialog"
    title="ConfigT"
    top="10vh"
    width="1000px"
    :center="true"
    :close-on-click-modal="false"
    :append-to-body="true"
    @close="() => { showDialog = false; configTObj.show = false }"
  >
    <iframe
      frameborder="no"
      border="0"
      width="100%"
      height="100%"
      :src="configTIframeUrl"></iframe>
  </el-dialog>
</template>
<script>
import Tool from '@/assets/js/utils'
export default {
  props: {
    configTObj: {
      type: Object,
      default () {
        return {
          show: false,
          bindPeerId: '',
          ip: '',
          showIP: false
        }
      }
    }
  },
  data () {
    return {
      showDialog: false,
      configTIframeUrl: '',
      itemInfo: null
    }
  },
  async created () {
    if (this.$store.state.common.baseUrl.openNewConfigT) {
      if (this.$store.state.packList) {
        const packArr = this.$store.state.packList.filter(m => m.peerId == this.configTObj.bindPeerId)
        this.itemInfo = packArr[0]
      } else {
        await this.axios.get(`/ccp/tvucc-device/device/getDevice?peerId=${this.configTObj.bindPeerId}`)
          .then(res => {
            if (res.data.errorCode == '0x0') {
              this.itemInfo = res.data.result
            }
          }).catch(() => {
          })
      }
      if (this.itemInfo) {
        if (this.itemInfo.platform && this.itemInfo.fs && this.itemInfo.peerId) {
          const platform = Number(this.itemInfo.platform)
          const fs = Number(this.itemInfo.fs)
          if (platform == 84 && fs >= 77148 || platform == 10084 && fs >= 81290) {
            const version = platform == 84 && fs >= 77148 ? 'V3' : 'V4'
            this.configTIframeUrl = `${window.location.origin}/newConfigT/${version}/${fs}/index.html?peerid=${Tool.basePeerId(this.itemInfo.peerId)}&migpine=1`
            this.showDialog = true
            return
          }
        }
      }
    }

    this.configTObj.show = false
    const obj = {
      peerid: this.configTObj.bindPeerId,
      showIP: this.configTObj.showIP,
      flag: this.$store.state.common.baseUrl.openNewConfigT || false
    }
    if (this.configTObj.ip) obj.ip = this.configTObj.ip
    Tool.openConfigT(obj)
  }
}
</script>
